
import { defineComponent, handleError } from "vue";
import InputText from "primevue/inputtext";
import { mapGetters } from "vuex";
import EmployeeService from "@/services/EmployeeService";

const employeeService = new EmployeeService(
  process.env.VUE_APP_ABSTRACTION_API,
);

export default defineComponent({
  components: {
    InputText,
  },
  emits: ["update:employeeId", "fetchEmployee"],
  props: {
    nextInput: {
      type: String,
      required: false,
    },
    employeeId: {
      type: String,
      required: true,
    },
    handleErrorAndFocus: {
      type: Function,
      required: true,
    },
    focusInput: {
      type: Function,
      required: true,
    },
    rowClass: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      employeeName: "",
    };
  },
  created() {
    if (this.getUserIdAsEmployeeId) {
      this.$emit("update:employeeId", this.getUser.user_id);
      this.$nextTick(() => {
        this.handleEmployeeID();
      });
    }
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getUserIdAsEmployeeId: "scan/getUserIdAsEmployeeId",
      getUser: "session/getUser",
    }),
  },
  methods: {
    handleEmployeeID() {
      if (this.employeeId) {
        employeeService
          .getEmployeeByID(
            this.getClient,
            this.employeeId,
            "emp_id name multiple_jobs",
            "",
          )
          .then((resp: any) => {
            if (resp.emp_items && resp.emp_items[0]) {
              const employee = resp.emp_items[0];
              this.employeeName = employee.name;

              this.$emit("update:employeeId", employee.emp_id);
              this.$emit("fetchEmployee", employee);
            } else {
              this.$emit("update:employeeId", "");
              this.employeeName = "";
              throw new Error(this.employeeId + " is not a valid employee.");
            }
          })
          .catch((err) => {
            this.handleErrorAndFocus(err.toString(), "employee_id");
          });
        this.nextInput && this.focusInput(this.nextInput);
      }
    },
    focus() {
      (this.$refs["employee_id"] as any).$el.focus();
      (this.$refs["employee_id"] as any).$el.select();
    },
  },
  watch: {
    employeeId: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.employeeName = "";
          if (this.getUserIdAsEmployeeId) {
            this.$emit("update:employeeId", this.getUser.user_id);
            this.$nextTick(() => {
              this.handleEmployeeID();
            });
          }
        }
      },
    },
  },
});
